<template>
  <v-col cols="12" class="pt-2">
    <div class="d-inline-flex" style="width: 100%">
      <v-autocomplete
        v-model="textTemplate"
        :data-cy="`${stepLabel}-credentialName`"
        outlined
        dense
        :items="templates"
        :loading="isLoadingTemplates"
        :search-input.sync="searchRequest"
        clearable
        hide-no-data
        item-text="name"
        item-value="name"
        :required="required"
        :rules="required ? [v => !!v || $lang.labels.required] : []"
        :label="$lang.labels.textTemplate"
        :placeholder="$lang.actions.startTyping"
        prepend-inner-icon="mdi-cloud-search-outline"
        :readonly="readonly"
        :class="required ? 'required-asterisk' : ''"
        @change="$emit('change', textTemplate)"
      />
      <v-btn
        icon
        light
        color="primary"
        class="ml-1"
        @click="searchTemplates(searchRequest)"
      >
        <v-icon
          dense
          small
        >
          mdi-refresh
        </v-icon>
      </v-btn>
      <v-btn
        text
        class="ml-1"
        color="primary"
        :disabled="!templateId"
        @click="openTemplate()"
      >
        {{ $lang.actions.openTemplate }}
      </v-btn>
    </div>
  </v-col>
</template>

<script>
import {
  getTextTemplatesUsingGET as getTemplates
} from '@/utils/api'

export default {
  props: {
    stepType: {
      type: String,
      required: true
    },
    properties: {
      type: Object,
      default: () => ({})
    },
    defaultValue: {
      type: String,
      default() {
        return this.properties.textTemplate
      }
    },
    stepLabel: {
      type: String,
      default: 'step'
    },
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      templateId: 0,
      textTemplate: '',
      templates: [],
      isLoadingTemplates: false,
      searchRequest: ''
    }
  },
  watch: {
    searchRequest: {
      handler(val) {
        if (val && val.length > 1) {
          this.searchTemplates(val)
        }
      },
      immediate: true
    },
    textTemplate: {
      handler(val) {
        if (val && val.length > 1) {
          this.searchTemplates(val)
        }
      }
    }
  },
  mounted() {
    this.textTemplate = this.defaultValue
    this.searchRequest = this.textTemplate
  },
  methods: {
    searchTemplates(val = '') {
      this.isLoadingTemplates = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      getTemplates(obj)
        .then((res) => {
          this.templates = res.data.data.items
          this.isLoadingTemplates = false

          const isSelected = res.data.data.items.find((x) => x.name === this.searchRequest)

          if (isSelected) this.templateId = isSelected.id
        })
        .catch((err) => {
          this.isLoadingTemplates = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })       
        })
    },
    openTemplate() {
      if (this.templateId) 
        window.open(`/${localStorage.selectedLanguage || 'en'}/templates/templates/edit/${this.templateId}`, '_blank')
    }
  }
}
</script>
