<template>
  <v-col cols="12">
    <div class="d-inline-flex" style="width: 100%">
      <v-autocomplete
        v-model="credentialName"
        :data-cy="`${stepLabel}-credentialName`"
        outlined
        dense
        :items="credentials"
        :loading="isLoadingCredentials"
        :search-input.sync="searchRequest"
        :readonly="readonly"
        clearable
        hide-no-data
        item-text="name"
        item-value="name"
        :label="$lang.labels.credential"
        :placeholder="$lang.actions.startTyping"
        :required="required"
        :rules="required ? [v => !!v || $lang.labels.required] : []"
        :class="required ? 'required-asterisk' : ''"
        prepend-inner-icon="mdi-cloud-search-outline"
        @change="onCredentialNameChange"
      />
      <v-btn
        icon
        light
        color="primary"
        class="ml-1"
        @click="searchCredentials(searchRequest)"
      >
        <v-icon
          dense
          small
        >
          mdi-refresh
        </v-icon>
      </v-btn>
      <v-btn
        text
        class="ml-1"
        color="primary"
        :disabled="!credentialName"
        @click="openCredential()"
      >
        {{ $lang.actions.openTemplate }}
      </v-btn>
    </div>
  </v-col>
</template>

<script>
import {
  getProcessCredentialsUsingGET as getCredentials
} from '@/utils/api'
import { isJavaVariable } from '@/utils/helpers'
import { mapActions } from 'vuex'

export default {
  props: {
    stepType: {
      type: String,
      required: true
    },
    properties: {
      type: Object,
      default: () => ({})
    },
    isSftp: {
      type: Boolean,
      default: false
    },
    isSshKey: {
      type: Boolean,
      default: false
    },
    stepLabel: {
      type: String,
      default: 'step'
    },
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    credentialAttrName: {
      type: String,
      default: 'credentialName'
    },
    inputHasToBeValidated: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      credentialName: '',
      credentials: [],
      isLoadingCredentials: false,
      searchRequest: '',
      isSshKeyInnerValue: this.isSshKey,
      isSftpInnerValue: this.isSftp
    }
  },
  computed: {
    credentialType() {
      switch (this.stepType) {
      case 'EMAIL':
        return 'SMTP'
      case 'PROCESS_SETTING':
        return 'PROCESS'
      case 'S3':
        return 'AWS'
      case 'JS':
      case 'GROOVY':
      case 'PYTHON':
        return 'SCRIPT'
      case 'MESSAGING':
        return this.properties.service || ''
      case 'GRAPHQL':
        return 'REST'
      case 'REST':
        return this.properties.authType === 'BEARER' 
          ? 'REST_BEARER' 
          : this.properties.authType === 'BASIC' 
            ? 'REST_BASIC' 
            : 'REST'
      case 'FTP':
        return this.isSftpInnerValue ? 'SFTP' : 'FTP'
      case 'CHAT_GPT':
        return 'OPEN_AI'
      case 'SSH':
        return this.isSshKeyInnerValue ? 'SSH_KEY' : 'SSH'
      default:
        return this.stepType
      }
    }
  },
  watch: {
    searchRequest: {
      handler(val) {
        if (val && val.length > 1) {
          this.searchCredentials(val)
        }
      },
      immediate: true
    },
    credentialName: {
      handler(val) {
        if (val && val.length > 1) {
          this.searchCredentials(val)
        }
      }
    },
    isSshKeyInnerValue(val) {
      if (val) {
        this.$emit('updateIsSshKey', val)
      }
    }
  },
  mounted() {
    this.credentialName = this.properties[this.credentialAttrName]
    this.searchRequest = this.credentialName
  },
  methods: {
    ...mapActions('app', ['addSnackbar']),
    searchCredentials(val = '') {
      if (isJavaVariable(val)) {

        this.addSnackbar({
          message: this.$lang.errors.credentialVariableName,
          timeout: 5000,
          color: 'error'
        })

        return
      }

      this.isLoadingCredentials = true

      const obj = {}

      if (val && val.length > 1) obj.name = val
      
      obj.type = this.credentialType

      getCredentials(obj)
        .then((res) => {
          this.credentials = res?.data?.data?.items || []
          this.isLoadingCredentials = false

          if (this.credentials.length === 0 && this.stepType === 'FTP' && this.isSftpInnerValue) {
            this.isSftpInnerValue = false
            this.searchCredentials(val)
          }
          if (this.credentials.length === 0 && this.stepType === 'SSH' && !this.isSshKeyInnerValue) {
            this.isSshKeyInnerValue = true
            this.searchCredentials(val)
          }
        })
        .catch((err) => {
          this.isLoadingCredentials = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })         
        })
    },
    openCredential() {
      const obj = {}

      if (isJavaVariable(this.credentialName)) {

        this.addSnackbar({
          message: this.$lang.errors.credentialVariableName,
          timeout: 5000,
          color: 'error'
        })

        return
      }

      obj.name = this.credentialName
      obj.type = this.credentialType

      getCredentials(obj)
        .then((res) => {
          if (res?.data?.data?.items?.length === 1) {
            window.open(`/${localStorage.selectedLanguage || 'en'}/credentials/edit/${res.data.data.items[0].id}`, '_blank')
          } else if (res?.data?.data?.items?.length > 1) {
            const filteredMatch = res.data.data.items.filter((x) => x.name === this.credentialName)

            if (filteredMatch.length === 1) {
              window.open(`/${localStorage.selectedLanguage || 'en'}/credentials/edit/${filteredMatch[0].id}`, '_blank')
            } else {
              this.addSnackbar({
                message: this.$lang.errors.multipleCredentials,
                timeout: 5000,
                color: 'error'
              })
            }
          } else {
            this.addSnackbar({
              message: this.$lang.errors.somethingWentWrong,
              timeout: 5000,
              color: 'error'
            })
          }
        })
        .catch((err) => {
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })
        })
    },
    onCredentialNameChange(val) {
      if (this.inputHasToBeValidated 
        && (!isJavaVariable(this.returnNameOrString(val))
        && !this.credentials.map((x) => x.name).includes(this.returnNameOrString(val)))) {

        this.credentialName = ''

      } else {
        this.$emit('change', this.credentialName)
      }
    },
    returnNameOrString(item) {
      return item && typeof item !== 'string' ? item.name : item
    }
  }
}
</script>
