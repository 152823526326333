<template>
  <v-autocomplete
    v-model="localeName"
    :data-cy="`${stepLabel}-locale`"
    outlined
    dense
    :items="availableLanguages"
    :search-input.sync="searchRequest"
    clearable
    hide-no-data
    item-value="value"
    item-text="text"
    :label="$lang.labels.locale"
    :placeholder="$lang.actions.startTyping"
    :readonly="readonly"
    :required="required"
    :rules="required ? [v => !!v || $lang.labels.required] : []"
    :class="required ? 'required-asterisk' : ''"
    @change="$emit('change', localeName)"
  />
</template>

<script>
import { SUPPORTED_LANGUAGES } from '@/utils/constants'

export default {
  name: 'LocalesSelect',
  props: {
    properties: {
      type: Object,
      default: () => ({})
    },
    defaultValue: {
      type: String,
      default() {
        return this.properties.locale
      }
    },
    stepLabel: {
      type: String,
      default: 'step'
    },
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localeName: '',
      searchRequest: '',
      languages: SUPPORTED_LANGUAGES.sort()
    }
  },
  computed: {
    availableLanguages() {
      return this.languages.map((x) => {
        return { text: this.$lang.labels[x], value: x }
      })
    }
  },
  mounted() {
    this.localeName = this.defaultValue
    this.searchRequest = this.localeName
  }
}
</script>
