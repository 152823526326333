<template>
  <v-select
    v-model="selectedAction"
    :items="items"
    outlined
    dense
    :required="required"
    :readonly="readonly"
    :label="label"
    :class="required ? 'required-asterisk' : ''"
    :rules="required ? [v => !!v || $lang.labels.required] : []"
    :data-cy="`${stepLabel}-action`"
    :disabled="disabled"
    @change="$emit('change', selectedAction)"
  />
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    defaultValue: {
      type: String,
      default: ''
    },
    stepLabel: {
      type: String,
      default: 'step'
    },
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default() {
        return this.$lang.labels.action
      }
    }
  },
  data() {
    return {
      selectedAction: ''
    }
  },
  mounted() {
    this.selectedAction = this.defaultValue
  }
}
</script>
