<template>
  <v-select
    v-model="selectedEngine"
    :items="formattedScriptEngines"
    :label="$lang.labels.scriptEngine"
    outlined
    dense
    :required="required"
    :readonly="readonly"
    :data-cy="`${stepLabel}-scriptEngine`"
    :class="required ? 'required-asterisk' : ''"
    @change="$emit('change', selectedEngine || DEFAULT_SCRIPT_ENGINE)"
  ></v-select>
</template>

<script>
import { SCRIPT_ENGINES, DEFAULT_SCRIPT_ENGINE } from '../constants'

export default {
  props: {
    defaultValue: {
      type: String,
      default: ''
    },
    stepLabel: {
      type: String,
      default: 'step'
    },
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      DEFAULT_SCRIPT_ENGINE,
      selectedEngine: ''
    }
  },
  computed: {
    formattedScriptEngines() {
      return SCRIPT_ENGINES.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    }
  },
  mounted() {
    if (!this.defaultValue) {
      this.selectedEngine = DEFAULT_SCRIPT_ENGINE
      this.$emit('change', DEFAULT_SCRIPT_ENGINE)
    } else {
      this.selectedEngine = this.defaultValue
    }
  }
}
</script>
