<template>
  <v-col cols="12" style="position: relative">
    <v-text-field
      v-model="time"
      outlined
      dense
      :required="required"
      :readonly="readonly"
      :rules="rules"
      :label="label"
      :data-cy="`${stepLabel}-${label}`"
      :class="required ? 'required-asterisk' : ''"
      @input="$emit('change', time)"
    />
    <v-btn
      class="button-help"
      style="position: absolute; top: 6px; right: 6px"
      icon
      small
      :disabled="readonly"
      @click="$emit('openDateTimeModal')"
    >
      <v-icon small color="info">mdi-clock</v-icon>
    </v-btn>
  </v-col>
</template>

<script>
export default {
  name: 'TextInputWithClockButton',
  props: {
    rules: {
      type: Array,
      default: () => []
    },
    defaultValue: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default() {
        return this.$lang.labels.query
      }
    },
    stepLabel: {
      type: String,
      default: 'step'
    },
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      time: this.defaultValue
    }
  },
  watch: {
    defaultValue: {
      handler(val) {
        this.time = val
      }
    }
  },
  beforeDestroy() {
    this.$set(this, 'time', null)
  }
}
</script>
