<template>
  <v-row :cols="showSingleResultToggle ? '8' : '12'">
    <v-col>
      <v-text-field
        v-model="targetObjectVal"
        outlined
        dense
        :label="$lang.labels.targetObject"
        :rules="[(v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
        :readonly="readonly"
        :data-cy="`${stepLabel}-targetObject`"
        @input="$emit('change', {key: 'targetObject', value: targetObjectVal})"
      />
    </v-col>

    <v-col v-if="showSingleResultToggle" cols="4">
      <v-switch
        v-model="singleResultVal"
        :label="$lang.labels.singleResult"
        class="pl-3 mt-1"
        @change="$emit('change', {key: 'singleResult', value: singleResultVal})"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    defaultTargetObject: {
      type: String,
      default: ''
    },
    defaultSingleResult: {
      type: Boolean,
      default: null
    },
    showSingleResultToggle: {
      type: Boolean,
      default: false
    },
    stepLabel: {
      type: String,
      default: 'step'
    },
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      targetObjectVal: '',
      singleResultVal: false
    }
  },
  mounted() {
    this.targetObjectVal = this.defaultTargetObject
    this.singleResultVal = this.defaultSingleResult
  }
}
</script>
