<template>
  <v-autocomplete
    v-model="selectedEncoding"
    :items="encodingTypes"
    :label="$lang.labels.encoding"
    outlined
    dense
    :required="required"
    :readonly="readonly"
    :rules="required ? [v => !!v || $lang.labels.required] : []"
    :class="required ? 'required-asterisk' : ''"
    :data-cy="`${stepLabel}-scriptEngine`"
    @change="$emit('change', selectedEncoding)"
  ></v-autocomplete>
</template>

<script>
import {
  getEncodingTypesUsingGET as getEncodingTypes
} from '@/utils/api'

export default {
  props: {
    defaultValue: {
      type: String,
      default: ''
    },
    stepLabel: {
      type: String,
      default: 'step'
    },
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      encodingTypes: [],
      selectedEncoding: ''
    }
  },
  mounted() {
    this.selectedEncoding = this.defaultValue
    getEncodingTypes()
      .then((res) => {
        this.encodingTypes = res.data.data.sort()
      })
      .catch((err) => {
        this.err = err
      })
  }
}
</script>
